import { Container, Col, Row } from "react-bootstrap";
import { BsEnvelope, BsFacebook, BsInstagram, BsPhone } from "react-icons/bs";
import { GiRotaryPhone } from "react-icons/gi";
import { SiGooglemaps } from "react-icons/si";
import { IconContext } from "react-icons";
import "./Footer.css";

function Footer() {
  return (
    <Container fluid className="footer-container">
      <Row className="footer-content">
        <Col md={4} className="footer-section">
          <h2 className="footer-heading">Horário de Funcionamento</h2>
          <ul className="footer-hours">
            <li>2ª Feira 11:30 - 23:00</li>
            <li>3ª Feira 11:30 - 23:00</li>
            <li>4ª Feira 11:30 - 23:00</li>
            <li>5ª Feira 11:30 - 23:00</li>
            <li>6ª Feira 11:30 - 23:00</li>
            <li>Sábado 11:30 - 23:00</li>
            <li>Domingo 11:30 - 23:00</li>
          </ul>
        </Col>
        <Col md={4} className="footer-section">
          <h2 className="footer-heading">Contactos</h2>
          <IconContext.Provider
            value={{ className: "contact-icon", size: "1.5em" }}
          >
            <div className="contact">
              <GiRotaryPhone /> <span>+351 262 789 262</span>
            </div>
            <div className="contact">
              <BsPhone /> <span>934033614</span>
            </div>
            <div className="contact">
              <SiGooglemaps /> <span>Av. do Mar 64, 2520-205 Peniche</span>
            </div>
          </IconContext.Provider>
          <div className="social-links">
            <IconContext.Provider value={{ size: "2em" }}>
              <a
                href="https://www.facebook.com/yoyokebabpeniche"
                target="_blank"
                rel="noreferrer"
                className="social-icon"
              >
                <BsFacebook
                  color="#1877F2"
                  style={{ width: "10%", height: "100%" }}
                />
              </a>
              <a
                href="https://www.instagram.com/yoyo_kebab_pizza/"
                target="_blank"
                rel="noreferrer"
                className="social-icon"
              >
                <BsInstagram
                  color="#ffffff"
                  style={{ width: "10%", height: "100%" }}
                />
              </a>
              <a
                href="https://glovoapp.com/pt/pt/"
                target="_blank"
                rel="noreferrer"
                className="social-icon"
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/en/8/82/Glovo_logo.svg"
                  alt="Glovo"
                  style={{ width: "20%", height: "100%" }}
                />
              </a>
              <a
                href="https://www.ubereats.com/"
                target="_blank"
                rel="noreferrer"
                className="social-icon"
              >
                <img
                  src="./assets/uber-eats.svg"
                  alt="Uber Eats"
                  style={{ width: "30%", height: "100%" }}
                />
              </a>
            </IconContext.Provider>
          </div>
        </Col>
        <Col md={4} className="footer-section">
          <h2 className="footer-heading">Vem ter connosco!</h2>
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3085.0354871507666!2d-9.381268487533642!3d39.3554329715116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd1f475561d6af03%3A0x20070cc4af9133bb!2sYo-Yo_Kebab_Pizzas!5e0!3m2!1sen!2spt!4v1683412906879!5m2!1sen!2spt"
            width="100%"
            height="300"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="footer-map"
          ></iframe>
        </Col>
      </Row>
      <Row className="footer-bottom">
        <Col className="text-center">
          <div className="copyright">
            &copy; {new Date().getFullYear()} <strong>YoYo Kebab</strong>. All
            Rights Reserved.
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
