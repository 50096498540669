import "./Menu.css";
import { useEffect, useRef, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import Isotope from "isotope-layout";

const button0 = "Mostrar Tudo";
const button1 = "Kebab";
const button2 = "Pizza";

const button3 = "Outros";

const filters = ["*", ".kebab", ".pizza", ".outro"];
const menuId = "menu";

async function fetchMenu() {
  let url = process.env.REACT_APP_CONNECTION_STRING;
  let rep = process.env.REACT_APP_ITEM_REPOSITORY;
  let response = await fetch(`${url}/${rep}?limit=${100}`, { mode: "cors" });
  return response.json();
}

function Dish(props) {
  let price;
  if (props.prices.length === 2) {
    price = (
      <>
        {props.type.toLowerCase() === "pizza" ||
        props.type.toLowerCase() === "outro" ? (
          <>
            <span className="price-small">{`Media: ${props.prices[0]}€`}</span>
            <span className="price-large">{` /Grande: ${props.prices[1]}€`}</span>
          </>
        ) : (
          <>
            <span className="price-small">{`${props.prices[0]}€`}</span>
            <span className="price-large">{` /Menu: ${props.prices[1]}€`}</span>
          </>
        )}
      </>
    );
  } else {
    price = <span className="price">{`${props.prices[0]}€`}</span>;
  }

  return (
    <Col className={`menu-item ${props.type}`} lg={6}>
      <div className="menu-content">
        <div className="menu-name">{props.name}</div>
        <div className="menu-price">{price}</div>
      </div>
      <div className="menu-ingredients">
        {props.ingredients.map((i, idx) =>
          idx < props.ingredients.length - 1 ? i.name + ", " : i.name
        )}
      </div>
    </Col>
  );
}

function mk_button(sel, onClick, value, text) {
  let className;
  if (value === sel) className = "filter-active";
  else className = "";
  return (
    <li key={value} data-filter="*" onClick={onClick} className={className}>
      {text}
    </li>
  );
}

function mk_buttons(sel, setSel, list) {
  let buttons = [];
  for (let i = 0; i < list.length; i = i + 1)
    buttons.push(mk_button(sel, () => setSel(i), i, list[i]));
  return buttons;
}

function Menu() {
  let n = 0;

  let storedMenu = localStorage.getItem(menuId);
  if (storedMenu === null) storedMenu = [];
  else storedMenu = [];

  const [menu, setMenu] = useState(storedMenu);
  const [sel, setSel] = useState(0);
  const isotope = useRef();

  useEffect(() => {
    fetchMenu().then((r) => {
      let menu = r.docs;
      localStorage.setItem(menuId, JSON.stringify(menu));
      setMenu(menu);
    });
  }, []);

  useEffect(() => {
    isotope.current = new Isotope(".menu-container", {
      itemSelector: ".menu-item",
      layoutMode: "fitRows",
    });
    return () => isotope.current.destroy();
  }, [menu]);

  useEffect(() => {
    isotope.current.arrange({ filter: filters[sel] });
  }, [sel]);
  return (
    <Container>
      <div className="menu">
        <Row>
          <Col id="menu-flters" xs lg={12}>
            <ul>
              {mk_buttons(sel, setSel, [button0, button1, button2, button3])}
            </ul>
          </Col>
        </Row>
        <Row className="menu-container">
          {menu.map(({ nome, tipo, precos, ingredients, bigPrice }) => (
            <Dish
              type={tipo.value.nome.toLowerCase()}
              key={n++}
              name={nome}
              prices={precos.map((p) => p.preco)}
              bigPrice={bigPrice}
              ingredients={ingredients}
            />
          ))}
        </Row>
      </div>
    </Container>
  );
}
export default Menu;
