import { Container, Col, Row } from 'react-bootstrap'

function Food() {
    return (
        <Container>
            <Row className="justify-content-center">
                <Col lg={4} md={4} sm={8}>
                    <h1 className="about-title">Our Food</h1>
                </Col>
                <Col lg={4} md={4} sm={10} className="about-text">
                    Feito com os melhores ingredientes, a pensar em si.
                </Col>
            </Row>
        </Container>
    )
}
export default Food
