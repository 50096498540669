import React, { useState, useEffect } from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import "./About.css";
import "bootstrap-icons/font/bootstrap-icons.css";

// Generate a random avatar
const generateRandomAvatar = (name) => {
  const colors = ["#000000"];
  const initials = name
    .split(" ")
    .map((word) => word[0])
    .join("");
  const color = colors[Math.floor(Math.random() * colors.length)];
  return `https://ui-avatars.com/api/?name=${initials}&background=${color.replace(
    "#",
    ""
  )}&color=fff&size=128`;
};

// Convert star rating to a numeric value
const getStarRating = (rating) => {
  const stars = ["ONE", "TWO", "THREE", "FOUR", "FIVE"];
  return stars.indexOf(rating) + 1;
};

// ReviewCard component
function ReviewCard({ review }) {
  const starCount = getStarRating(review.starRating);
  const avatar = review.reviewer.displayName
    ? generateRandomAvatar(review.reviewer.displayName)
    : "https://via.placeholder.com/128?text=No+Avatar"; // Placeholder if no name is provided

  return (
    <div className="review-card">
      <div className="review-header">
        <img src={avatar} alt="Avatar" className="review-avatar" />
        <div className="review-content">
          <h5 className="reviewer-name">{review.reviewer.displayName}</h5>
          <div className="review-stars mb-2">
            {[...Array(starCount)].map((_, i) => (
              <i key={i} className="bi bi-star-fill star-yellow"></i>
            ))}
            {[...Array(5 - starCount)].map((_, i) => (
              <i key={i + starCount} className="bi bi-star star-grey"></i>
            ))}
          </div>
          <p className="review-date">
            {new Date(review.createTime).toLocaleDateString()}
          </p>
        </div>
      </div>
      <p
        className={`review-body ${
          review.comment.length > 300 ? "scrollable" : ""
        }`}
      >
        {review.comment}
      </p>
    </div>
  );
}

// About component
function About() {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    async function loadReviews() {
      const randomNumber = Math.floor(Math.random() * 20); // Generate a random number
      const reviewsPath = `./reviews/reviews${randomNumber}.json`; // Path with random number
      try {
        const reviewsData = await import(`${reviewsPath}`);
        if (Array.isArray(reviewsData.reviews)) {
          setReviews(reviewsData.reviews);
        } else {
          console.error("reviewsData is not an array:", reviewsData);
        }
      } catch (error) {
        console.error("Error loading reviews data:", error);
      }
    }

    loadReviews();
  }, []);

  return (
    <Container>
      <Row className="d-flex align-items-center">
        {/* Title Column */}
        <Col md={4} className="text-left">
          <h1 id="aboutContainer" className="review-title">
            {"O que dizem os nossos clientes?".toUpperCase()}
          </h1>
        </Col>

        {/* Reviews Carousel Column */}
        <Col md={8}>
          <Carousel
            pause={false}
            controls={false}
            indicators={false}
            interval={3000}
          >
            {reviews.map(
              (review, index) =>
                review.comment && (
                  <Carousel.Item key={index}>
                    <div className="d-flex justify-content-center">
                      <ReviewCard review={review} />
                    </div>
                  </Carousel.Item>
                )
            )}
          </Carousel>
        </Col>
      </Row>
    </Container>
  );
}

export default About;
