import React, { useState, useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../assets/logo.png";
import "./NavBar.css";

function bold(s, className = "nav-elem") {
  return <b className={className}>{s}</b>;
}

function NavBar() {
  const [scrolling, setScrolling] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuRef = useRef(null); // Reference to the menu container
  const toggleRef = useRef(null); // Reference to the toggle button

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev); // Toggle the menu state
  };

  const handleClickOutside = (event) => {
    // Close menu if the click is outside of the menu or toggle button
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      !toggleRef.current.contains(event.target)
    ) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("click", handleClickOutside); // Listen for clicks outside the menu

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("click", handleClickOutside); // Cleanup the event listener
    };
  }, []);

  const title = <img src={logo} alt="logo" id="logo" />;
  const menu = bold("Menu".toUpperCase());
  const about = bold("Sobre Nós".toUpperCase());
  const contact = bold("Contacto".toUpperCase());

  return (
    <Navbar
      id="navigationBar"
      collapseOnSelect
      fixed="top"
      expand="lg"
      className={scrolling ? "scrolled" : ""}
    >
      <Container>
        <Navbar.Brand href="#home">{title}</Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={toggleMenu} // Toggle the menu
          className={isMenuOpen ? "open" : ""} // Add 'open' class for rotation
          ref={toggleRef} // Attach reference to toggle button
        />
        <Navbar.Collapse id="basic-navbar-nav" ref={menuRef} in={isMenuOpen}>
          {/* Attach ref to the menu */}
          <Nav className="me-auto">
            <Nav.Link href="#menu">{menu}</Nav.Link>
            <Nav.Link href="#about">{about}</Nav.Link>
            <Nav.Link href="#contact">{contact}</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
